<template>
  <div id="app">
    <!--    <div class="ww-head" />-->
    <van-swipe :autoplay="5000" :show-indicators="false">
      <van-swipe-item v-for="(item,index) in images" :key="index">
        <van-image width="100%" height="190" :src="item.image" />
      </van-swipe-item>
    </van-swipe>
    <div style="margin: 0 5px;">
      <van-grid :column-num="3" square>
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp id="launch-btn" username="gh_e27de8252b43" path="pages/index/index" class="mm-flex">
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <div class="mm-flex">
              <img height="50" width="50" src="http://main.letour.ltd/img/1-1.png">
              <div class="mm-title">汽车票
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_e26c22d45b0e"
            path="pages/index/index"
            class="mm-flex"
          >
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <img height="50" width="50" src="http://main.letour.ltd/img/1-2.png" style="text-align: right">
              <div class="mm-flex">
              <div class="mm-title">城际快车
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_e26c22d45b0e"
            path="pages/linemap/index?item=1"
            class="mm-flex"
          >
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <img height="50" width="50" src="http://main.letour.ltd/img/1-13.png" style="text-align: right">
              <div class="mm-flex">
              <div class="mm-title">包车出行
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_e26c22d45b0e"
            path="pages/linemap/index?item=4"
            class="mm-flex"
          >
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <div class="mm-flex">
              <img height="50" width="50" src="http://main.letour.ltd/img/1-4.png" style="text-align: right">
              <div class="mm-flex">
              <div class="mm-title">景区直通车
              </div>
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_e26c22d45b0e"
            path="pages/linemap/index?item=5"
            class="mm-flex"
          >
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <div class="mm-flex">
              <img height="50" width="50" src="http://main.letour.ltd/img/1-5.png" style="text-align: right">
              <div class="mm-flex">
              <div class="mm-title">校园直通车
              </div>
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_e26c22d45b0e"
            path="/pagesgroup/pages/linemap/carpoolindex?item=7"
            class="mm-flex"
          >
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <img height="50" width="50" src="http://main.letour.ltd/img/1-6.png" style="text-align: right">
              <div class="mm-flex">
              <div class="mm-title">拼团出行
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item>
          <div class="mm-flex" @click="mm1">
            <img height="50" width="50" src="/img/1-7.png">
            <div class="mm-title">在线检车
            </div>
          </div>
        </van-grid-item>
        <!--        <van-grid-item>
          <div class="mm-flex" @click="mm2">
            <img height="50" width="50" src="/img/1-8.png">
            <div class="mm-title">充电服务
            </div>
          </div>
        </van-grid-item>-->
        <van-grid-item v-if="visible">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_579321d9eb65"
            path="/pages/index/index"
            class="mm-flex"
          >
            <script type="text/wxtag-template">
              <style>
              div {
              font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
              }

              .mm-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              }

              .mm-title {
              color: #303133;
              font-size: 14px;
              font-weight: bold;
              }
              </style>
              <img height="50" width="50" src="http://main.letour.ltd/img/1-8.png" style="text-align: right">
              <div class="mm-flex">
              <div class="mm-title">充电服务
              </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-grid-item>
        <van-grid-item>
          <div class="mm-flex" @click="mm3">
            <img height="50" width="50" src="/img/1-9.png">
            <div class="mm-title">在线商城
            </div>
          </div>
        </van-grid-item>
        <!--        <van-grid-item>
          <div class="mm-flex" @click="mm4">
            <img height="50" width="50" src="http://main.letour.ltd/img/1-10.png">
            <div class="mm-title">永平公交线路查询
            </div>
          </div>
        </van-grid-item>
        <van-grid-item>
          <div class="mm-flex" @click="mm5">
            <img height="50" width="50" src="http://main.letour.ltd/img/1-11.png">
            <div class="mm-title">在线客服
            </div>
          </div>
        </van-grid-item>-->
      </van-grid>

      <div class="letour-footer">
        <div style="font-size: 16px;color: #606266;text-align: center;padding: 32px;font-weight: bold;">
          订票就用彩云通 方便省时不排队
        </div>
        <div @click="makePhoneCall">客服电话：<span style="color: #0FA0F0;">400 600 5180</span>
        </div>
        <div>大理交运集团 版权所有</div>
        <div>CopyRight © 2021-{{ year }} DTG. All Rights Reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Index',
  data() {
    return {
      visible: false,
      year: new Date().getFullYear(),
      images: [{ 'image': '/img/head/1.png', 'ctime': '2021-02-20 08:24:53', 'id': 1, 'state': 0, 'title': '春暖花开  奔你而来' },
        { 'image': '/img/head/2.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/3.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/4.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/5.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/6.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/7.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/8.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 },
        { 'image': '/img/head/9.png', 'ctime': '2021-02-20 08:24:53', 'id': 2, 'state': 0 }
      ]
    }
  },
  created() {
    this.getWechatConfig()
    // this.getImg()
  },
  methods: {
    getWechatConfig() {
      const that = this
      axios.get('/center/ltUser/createJsapiSignature?appid=wx7f47100180759027&url=https%3A%2F%2Fmain.letour.ltd%2F')
        .then(res => {
          const data = res.data.data
          console.log(data)
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
            appId: 'wx7f47100180759027', // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: ['openLocation'], // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp', 'wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          })
          wx.ready(function() {
            that.visible = true
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    /* getImg() {
      axios.get('https://wechatcustomizedpassengertransport.letour.ltd/letour/mp/getAdImg')
        .then(res => {
          // console.log(res)
          this.images = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },*/
    mm1() {
      window.location.href = 'https://carinspection.letour.ltd/'
    },
    mm2() {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' +
          'appid=wx2e37993fee3db235&redirect_uri=https%3A%2F%2Fwww.hcharger.com%2Fvue-mint%2Fdist%2Findex.html%3FoperatorId%3DJ901' +
          '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    },
    mm3() {
      window.location.href = 'https://integralmall.letour.ltd/'
    },
    mm4() {
      this.$router.push({ path: '/line' })
    },
    mm5() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc2feff8d6c2e17d5e'
    },
    makePhoneCall() {
      window.location.href = 'tel:4006005180'
    }
  }
}
</script>

<style>

body {
  background: #F2F3F7;
}

.ww-head {
  background-image: linear-gradient(0deg, #f1503b, #c82519 50%);
  position: absolute;
  top: 0;
  left: -25%;
  height: 7.25rem;
  width: 150%;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.mm-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.mm-title {
  color: #303133;
  font-size: 14px;
  font-weight: bold;
}

.letour-footer {
  margin-bottom: 30px;
  text-align: center;
  color: #909399;
  font-size: 12px;
}

</style>
